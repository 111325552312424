import { createApp } from 'vue';
import Oruga from '@oruga-ui/oruga-next';
// import '@oruga-ui/oruga-next/dist/oruga.css';
import '@oruga-ui/oruga-next/dist/oruga-full-vars.css';
import App from './App.vue';
import router from './router';
import FontAwesomeIcon from '@/utilities/fontawesome-icons';

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .use(Oruga, {
    iconComponent: 'vue-fontawesome',
    iconPack: 'fas',
  })
  .mount('#app');
