import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
  faPhone, faUser, faFlag, faHome, faAt,
} from '@fortawesome/free-solid-svg-icons';

library.add(faPhone);
library.add(faUser);
library.add(faFlag);
library.add(faHome);
library.add(faAt);
library.add(faLinkedin);

export default FontAwesomeIcon;
