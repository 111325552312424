<template>
  <div class=main>
  <div id="nav">
    <router-link to="/"><font-awesome-icon :icon="['fas', 'home']" /></router-link> |
    <router-link to="/diagnostiek">Diagnostiek</router-link> |
    <router-link to="/behandeling">Behandeling</router-link> |
    <router-link to="/diensten">Diensten</router-link> |
    <router-link to="/kwaliteit">Kwaliteit</router-link> |
    <router-link to="/contact">Contact</router-link> |
    <router-link to="/privacy">Privacy</router-link>

  </div>
  <router-view/>
</div>
  <div class="sidepanel">
  </div>
  <Footer/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

$base-color-red: #9d0200;
$base-color-blue: #1900ff;
$base-color-dark-blue: #10006a;

#app {
  font-family: Nunito,Helvetica,Roboto,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-left: auto;
  margin-right: auto;
  max-width: 50em;
}

.sidepanel {
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  top: 0; /* Stay at the top */
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  background-image: none;
  height:100%;
  background-position: left;
  background-position-x: -60px;
  // background-position: center;
  background-repeat: no-repeat;
  background-size: 200%;
  margin-left: 0;
}
@media only screen and (min-width: 1600px) {
  .sidepanel {
    width: 380px;
    background-size: 220%;
    background-image: url("./assets/jessica-lewis-8T-lK9FkzyI-unsplash.jpg");
    // background-image: url("./assets/giraffe.jpg");
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .sidepanel {
    width: 300px;
    background-size: 220%;
    background-image: url("./assets/jessica-lewis-8T-lK9FkzyI-unsplash.jpg");
  }
}

#nav {
  padding: 30px;

  a {
    font-family: Poppins, Ubuntu, Helvetica, Arial, sans-serif !important;
    font-weight: bold;
    font-size:larger;
    color: #2c3e50;
    text-decoration: none;

    &:hover {color: $base-color-blue;
    //box-shadow: 0 3px 8px 0 rgba(0,0,0,.17);
  }

    &.router-link-exact-active {
      color: $base-color-dark-blue;
    }
  }
}
body {
  background-color: white;
  margin:0px;
  background-color:aliceblue;
  margin-left: calc(100vw - 100%);
}

h3{
  font-family: Poppins, Ubuntu, Helvetica, Arial, sans-serif !important;
}

</style>

<script>
import { defineComponent } from 'vue';
import Footer from './components/Footer.vue';

export default defineComponent({
  name: 'App',
  components: {
    Footer,
  },
});

</script>
